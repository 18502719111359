<template lang="pug">
  div
    v-row
      v-col(cols="6")
        v-row
          v-col(cols="4")
            v-text-field(
              label="Start"
              type="date"
              clearable
              v-model="dateStart"
            )
          v-col(cols="4")
            v-text-field(
              label="End"
              type="date"
              clearable
              v-model="dateEnd"
            )
          v-col(cols="12")
            v-data-table(
              dense
              :headers="headers"
              :items="itemsDeliveredList"
              :server-items-length="itemsDeliveredGetCount"
              :loading="itemsDeliveredGetting"
              :page.sync="page"
              :items-per-page.sync="itemsPerPage"
            )
              template(v-slot:item="{ item }")
                tr
                  td {{ (item.stock || {}).description }}
                  td {{ item.quantity }}
                  td {{ item.unit }}
</template>
<script>
import orderDetailRepository from '@/repositories/order-detail.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [orderDetailItemsDeliveredGetVars, orderDetailItemsDeliveredGetVarNames] = requestVars({ identifier: 'items-delivered', pagination: true })

const orderDetailItemsDeliveredGetHandler = new VueRequestHandler(null, orderDetailItemsDeliveredGetVarNames, {}, true)

const tableVars = () => ({
  dateStart: null,
  dateEnd: null,
  headers: [
    { text: 'Item' },
    { text: 'Quantity', width: 100 },
    { text: 'Unit', width: 100 },
  ],
  page: 1,
  itemsPerPage: 10,
})

export default {
  name: 'InOutOrder',
  data () {
    return {
      ...tableVars(),
      ...orderDetailItemsDeliveredGetVars,
    }
  },
  created () {
    this.getOrderDetailItemsDelivered()
  },
  watch: {
    page () {
      this.getOrderDetailItemsDelivered()
    },
    itemsPerPage () {
      this.getOrderDetailItemsDelivered()
    },
    dateStart () {
      this.getOrderDetailItemsDelivered()
    },
    dateEnd () {
      this.getOrderDetailItemsDelivered()
    },
  },
  methods: {
    getOrderDetailItemsDelivered () {
      const handler = orderDetailItemsDeliveredGetHandler
      const repository = orderDetailRepository.itemsDelivered
      const params = this.getOutOrderReportParams()
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    getOutOrderReportParams () {
      const { dateStart, dateEnd, page, itemsPerPage } = this
      return {
        dateStart,
        dateEnd,
        paginate: 1,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
      }
    },
  },
}
</script>