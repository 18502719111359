import repository from './repository'

const resource = 'order-details'

export default {
  index () {
    return repository.get(resource)
  },
  itemsDelivered (params) {
    return repository.get(`${resource}/items-delivered`, { params })
  },
  store (data) {
    return repository.post(resource, data)
  },
  delete (id) {
    return repository.delete(`${resource}/${id}/delete`)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}/edit`, data)
  },
  validate (data) {
    return repository.post(`${resource}/validate`, data)
  },
  billings (customerId) {
    return repository.get(`${resource}/${customerId}/billings`)
  },
  exportV1 (params) {
    const conf = {
      headers: {
        Accept: 'application/xlsx',
      },
      responseType: 'blob',
      params,
    }
    return repository.get(`${resource}/export-v1`, conf)
  },
}
